////////// COLOR SYSTEM //////////

$blue: #5e50f9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #e91e63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: #434a54,
  gray-light: #aab2bd,
  gray-lighter: #e8eff4,
  gray-lightest: #e6e9ed,
  gray-dark: #0f1531,
  black: #000000,
);

$theme-colors: (
  primary: #1e39a1,
  secondary: #d8d8d8,
  success: #4fb0ab,
  info: #198ae3,
  warning: #fed713,
  danger: #fe7c96,
  light: #f8f9fa,
  dark: #3e4b5b,
  progray: #f0b9b9,
);

$theme-gradient-colors: (
  primary: linear-gradient(to bottom, #5781d1, #1e39a1),
  secondary: linear-gradient(to bottom, #e7ebf0, #868e96),
  success: linear-gradient(to top, #84d9d2, #07cdae),
  // info: linear-gradient(to bottom, #90caf9, #047edf 99%),
  info: linear-gradient(to top, #0279ff, #00a3f3),
  warning: linear-gradient(to bottom, #f6e384, #ffd500),
  danger: linear-gradient(to bottom, #ffbf96, #fe7096),
  light: linear-gradient(to bottom, #f4f4f4, #e4e4e9),
  dark: linear-gradient(89deg, #5e7188, #3e4b5b),
  low: linear-gradient(to bottom, #39aa83, #9e9ef0),
  lowup: linear-gradient(to left, #fc9e12, #b64fe6 99%),
  toy: linear-gradient(to right, #f5af19, #f12711f1 99%),
  live: linear-gradient(to right, #ff416c, #ff4b2b 99%),
  alive: linear-gradient(to right, #a8ff78, #78ffd6 99%),
  water: linear-gradient(to right, #00b09b, #96c93d 99%),
  spy: linear-gradient(to right, #ff9966, #ff5e62 99%),
  happy: linear-gradient(to right, #7f00ff, #e100ff 99%),
  aloa: linear-gradient(to right, #f7971e, #ffd200 99%),
  arora: linear-gradient(to right, #ee0979, #ff6a00 99%),
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f6f6f6;
$footer-bg: $content-bg;
$footer-color: color(dark);
$border-color: #ebedf2;
$circle-border: $white;

////////// COLOR VARIABLES //////////

////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500,
);

////////// SOCIAL COLORS //////////

////////// FONTS//

// $type1:     'Ubuntu', sans-serif;
$type1: 'Poppins', sans-serif;

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

$default-font-size: 0.875rem; // 14px as base font size

$text-muted: #9c9fa6;
$body-color: #343a40;

////////// FONT VARIABLES //////////

///////// CARD ////////
$card-description-color: #76838f;
$card-title-color: $body-color;
$card-bg-varient: #fff;
///////// CARD ////////

////////// SIDEBAR ////////
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #3e4b5b;
$sidebar-light-submenu-color: #888;
$sidebar-light-menu-active-bg: $white;
$sidebar-light-menu-active-color: theme-color(success);
$sidebar-light-menu-hover-bg: darken($sidebar-light-bg, 1%);
$sidebar-light-menu-hover-color: darken($sidebar-light-menu-color, 10%);
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 20%);
$sidebar-light-menu-icon-color: #bba8bff5;
$sidebar-light-menu-arrow-color: #9e9da0;
$sidebar-light-menu-border-color: #f2edf3;

$sidebar-dark-bg: #18151e;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #282037;
$sidebar-dark-menu-active-color: darken($white, 30%);
$sidebar-dark-menu-hover-bg: lighten($sidebar-dark-bg, 10%);
$sidebar-dark-menu-hover-color: color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);
$sidebar-dark-menu-icon-color: #bba8bff5;
$sidebar-dark-menu-arrow-color: #9e9da0;

$sidebar-menu-font-size: $default-font-size;
$sidebar-menu-padding-y: 1.125rem;
$sidebar-menu-padding-x: 2.25rem;

$sidebar-submenu-font-size: 0.8125rem;
$sidebar-submenu-item-padding: 0.75rem 2rem 0.75rem 2rem;

$sidebar-icon-font-size: 1.125rem;
$sidebar-arrow-font-size: 0.625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0.75rem 1.625rem 0.75rem 1.188rem;

$sidebar-mini-menu-padding: 0.8125rem 1rem 0.8125rem 1rem;

$sidebar-icon-only-menu-padding: 0.5rem 1.625rem 0.5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// NAVBAR ////////

$navbar-height: 70px;
$navbar-bg: #1e39a1;
$navbar-menu-color: #9c9fa6;
$navbar-font-size: 0.875rem;
$navbar-icon-font-size: 1.25rem;

///////// NAVBAR ////////

///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: 0.875rem;
$btn-padding-x: 2.5rem;
$btn-line-height: 1;

$btn-padding-y-xs: 0.5rem;
$btn-padding-x-xs: 0.75rem;

$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 0.81rem;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: 0.875rem;
$btn-font-size-xs: 0.625rem;
$btn-font-size-sm: 0.875rem;
$btn-font-size-lg: 0.875rem;

$btn-border-radius: 0.1875rem;
$btn-border-radius-xs: 0.1875rem;
$btn-border-radius-sm: 0.1875rem;
$btn-border-radius-lg: 0.1875rem;

///////// BUTTONS ////////

////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.375rem;

////////// TOOLTIP VARIABLES //////////

/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #000;
$input-font-size: 0.8125rem;

$input-padding-y: 0.875rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;

$input-padding-y-xs: 0.5rem;
$input-padding-x-xs: 0.75rem;

$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.81rem;

$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1.94rem;

$input-height: 2.875rem;
$input-height-sm: 2.575rem;
$input-height-lg: 3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////

$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
////////  DROPDOWNS ///////

//////// TABLES ////////

$table-accent-bg: $content-bg;
$table-hover-bg: $content-bg;
$table-cell-padding: 0.9375rem;
$table-border-color: $border-color;

$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);

//////// TABLES ////////

////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 2.5rem;
$card-padding-y: 2.5rem;
$card-padding-x: 2.5rem;
$card-border-radius: 0.3125rem;
$grid-gutter-width: 40px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////

////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: '/';

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;

////////// BREAD CRUMBS VARIABLES //////////

////////// MODALS VARIABLES //////////

$modal-inner-padding: 0.9375rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $content-bg;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform 0.4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #ebedf2;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
$text-gray: gray;
$circle-img-border: $white;
////////   TABS VARIABLES /////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////

////////// Vendor file asset path //////////
$mdi-font-path: '~@mdi/font/fonts';
$fa-font-path: '~font-awesome/fonts/';
$flag-icon-css-path: '~flag-icon-css/flags/';
$simple-line-font-path: '~simple-line-icons/fonts/';

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// TICKETS-TAB ///////
$tab-head-bg: darken(#f2edf3, 2%);
$accent-color: $white;
///////// TICKETS-TAB ///////

///////// LANDING PAGE ///////
$landing-bg: #1a142dfa;
$landing-bg-color: $white;
///////// LANDING PAGE ///////

///////// CHATS ///////
$chat-container-height: 100%;
$chat-bottom-btn: $white;
$form-focus-bg: $white;
///////// CHATS ///////

///////// KANBAN ///////
$private-btn-bg: #eae2ec;
///////// KANBAN ///////

$bullet-line-list-shape-bg: color(white) !default;
