::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar-nav-item-head-title {
  font-weight: 500;
  color: #1e39a1;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 2.25rem;
  .menu-icon-for-title {
    margin-left: 65px;
    font-size: 18px;
  }
}
.d-flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.f-wrap {
  flex-wrap: wrap;
}
.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.width-100-px {
  width: 100px;
}
.height-100-px {
  height: 100px;
}
.width-32-px {
  width: 32px;
}
.dynamicFormDiv {
  background: #f7f7f7;
  padding: 15px;
}
.pad10 {
  padding: 10px;
}
.border-1px-grey {
  border: 1px solid #f7f7f7;
}
.mar-rgt-10 {
  margin-right: 10px;
}
.doctor-calender-doctors-list-div {
  max-height: 375px;
  overflow: auto;
  .selected-doctor-calender-profile {
    margin-bottom: 1px;
    margin-top: 1px;
    background-color: #4fb0ab;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-color: #8dcfcc;
    .image-div {
      padding: 2px;
      img {
        width: 55px;
        border-radius: 100%;
      }
    }
    .info-div {
      padding: 2px 15px;
      h5 {
        text-transform: uppercase;
        margin: 0;
        font-size: 13px;
      }
      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
  .doctor-calender-profile {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    margin-bottom: 1px;
    margin-top: 1px;
    background-color: #fff;
    .image-div {
      padding: 2px;
      img {
        width: 55px;
        border-radius: 100%;
      }
    }
    .info-div {
      padding: 2px 15px;
      h5 {
        text-transform: uppercase;
        margin: 0;
        font-size: 11px;
      }
      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}
.doctor-available-time-div {
  max-height: 375px;
  overflow: auto;
}
.doctor-calender {
  .doctor-calender-div {
    flex: 1;
  }
  .doctor-slot-div {
    flex: 1;
    max-height: 375px;
    overflow: auto;
    .slots {
      display: flex;
      flex-wrap: wrap;
      .slot {
        margin: 4px auto;
        width: 100px;
      }
    }
  }
}
.custom-style-select {
  padding: 3px 10px;
  border-radius: 6px;
  font-size: 12px;
}
.custom-style-input {
  padding: 10px;
  border: 1px solid #ddd;
  :focus {
    border: 1px solid #ddd;
  }
}
.company-img-wrap {
  text-align: center;
  .add-company-logo {
    width: 70px;
    display: block;
    margin: auto;
  }
  .fileupload {
    .form-control-file {
      border: 1px solid #ddd;
      padding: 4px;
    }
  }
}
.modal-content {
  background-color: #fff;
}

.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.985em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.employee_fees_table {
  .fees_table_input {
    width: 140px;
    background-color: #f0f0f0;
    padding: 7px;
    border: transparent;
  }
  .fee_add_btn {
    background-color: #66f;
    color: #fff;
    box-shadow: none;
    border: none;
    width: 60px;
    padding: 4px;
  }
  .fee_remove_btn {
    background-color: #fe5c5c;
    color: #fff;
    box-shadow: none;
    border: none;
    width: 60px;
    padding: 4px;
  }
}
.refferral-request-status-div {
  display: flex;
  button {
    flex: 1;
    border: none;
    padding: 7px;
    background-color: #dbe2ff;
    font-size: 12px;
    font-weight: 500;
    margin: 0 5px;
    border-radius: 10px;
  }
  .active {
    background: #1e39a1;
    color: #fff;
  }
}
.sort-container {
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 10px;
  .sort-element {
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    cursor: move;
  }
}
.role_response_list_div {
  height: 320px;
  overflow: auto;
  overflow-x: hidden;
}
.role_response {
  margin: 5px 15px;
  .role_response_chip_add {
    display: inline-flex;
    flex-direction: row;
    background-color: #dbe2ff;
    border: none;
    cursor: default;
    height: 36px;
    outline: none;
    padding: 0;
    font-size: 14px;
    color: #333333;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
  }
  .role_response_chip_remove {
    display: inline-flex;
    flex-direction: row;
    background-color: #f9e6e5;
    border: none;
    cursor: default;
    height: 36px;
    outline: none;
    padding: 0;
    font-size: 14px;
    color: #333333;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
  }
  .role_response_chip-content {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  .role_response_chip-svg_add {
    color: #dbe2ff;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    transform: rotate(45deg);
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
  .role_response_chip-svg_add:hover {
    color: #666666;
  }
  .role_response_chip-svg_remove {
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
  .role_response_chip-svg_remove:hover {
    color: #666666;
  }
}
.swal2-modal .swal2-actions .swal2-styled {
  color: #fff;
}
.refer-doctor-background-image {
  // background-image: url("https://goapex.com/wp-content/uploads/2020/08/APEX-referral-program.png");
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center;
}
.center-div-referal-form {
  margin: auto;
  width: 50%;
  padding: 10px;
  backdrop-filter: saturate(180%) blur(10px);
  background-color: rgba(255, 255, 255, 0.95);
}
